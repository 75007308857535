import {AxiosRequestConfig} from 'axios'
import axiosAuthConfig from '../../../api/axiosAuthConfig'

const updateLanguage = (userId, payload, config?: AxiosRequestConfig | undefined) => {
  return axiosAuthConfig.put(`/users/profile/general/${userId}`, payload, config)
}
const CommonService = {
  updateLanguage,
}

export default CommonService
