import React from 'react'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../helpers'

type Props = {
  className?: string
  path: string
  svgClassName?: string
  small?: boolean
}

const KTSVG: React.FC<Props> = ({className = '', path, svgClassName = 'mh-50px', small = true}) => {
  return (
    <span className={`${small ? 'svg-icon' : ''} ${className}`}>
      <SVG src={toAbsoluteUrl(path)} className={svgClassName} />
    </span>
  )
}

export {KTSVG}
