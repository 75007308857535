import React from 'react'

const Content: React.FC = ({children}) => {
  return (
    <div id='gori_content_container' className='mx-10 mx-xxl-20'>
      {children}
    </div>
  )
}

export {Content}
