import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'
import '@formatjs/intl-relativetimeformat/polyfill'
import {FC} from 'react'
import {IntlProvider} from 'react-intl'
import {useLanguage} from './Metronici18n'

import enMessages from './messages/en.json'
import zhMessages from './messages/zh.json'

const allMessages = {
  en: enMessages,
  zh: zhMessages,
}

const I18nProvider: FC = ({children}) => {
  const {language} = useLanguage()
  const messages = allMessages[language]

  return (
    <IntlProvider locale={language} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}
