import {Outlet} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import {LoadingPage} from '../_gori/components'

import {Content, Footer, Header} from '../_gori/layout'
import {useStatesGlobal} from '../_gori/providers/StatesGlobalProvider'

const App = () => {
  const {statesGlobal} = useStatesGlobal()

  return (
    <>
      {statesGlobal?.loadingFullPage && <LoadingPage />}
      <Header />
      <Content>
        <Outlet />
        <ToastContainer position='top-right' autoClose={5000} />
      </Content>
      <Footer />
    </>
  )
}

export {App}
