import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js'

const SIGNATURE_ACCESS_KEY: any =
  process.env.REACT_APP_SIGNATURE_ACCESS_KEY || 'SIGNATURE_ACCESS_KEY'

const encryptValue = (value) => {
  const encryptedValue = CryptoJS.AES.encrypt(
    JSON.stringify(value),
    SIGNATURE_ACCESS_KEY
  ).toString()
  return encryptedValue
}

const decryptValue = (encryptedValue) => {
  const bytes = CryptoJS.AES.decrypt(encryptedValue, SIGNATURE_ACCESS_KEY)
  const decryptedValue = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  return decryptedValue
}

// BEGIN: Cookies
const getItemCookies = (key) => {
  const encryptedValue = Cookies.get(key)
  if (encryptedValue) {
    const decryptedValue = decryptValue(encryptedValue)
    return decryptedValue
  }
  return null
}

const setItemCookies = (key, value, options = {}) => {
  const encryptedValue = encryptValue(value)
  Cookies.set(key, encryptedValue, options)
}

const removeItemCookies = (key) => {
  Cookies.remove(key)
}

const clearItemCookies = () => {
  const cookies = Object.keys(Cookies.get())
  for (let i = 0; i < cookies.length; i++) {
    Cookies.remove(cookies[i])
  }
}
//  END: Cookies

// BEGIN: Local
const getItemLocalStorage = (key) => {
  const encryptedValue = localStorage.getItem(key)
  if (encryptedValue) {
    const decryptedValue = decryptValue(encryptedValue)
    return decryptedValue
  }
  return null
}

const setItemLocalStorage = (key, value) => {
  const encryptedValue = encryptValue(value)
  localStorage.setItem(key, encryptedValue)
}

const removeItemLocalStorage = (key) => {
  localStorage.removeItem(key)
}

const clearItemLocalStorage = () => {
  localStorage.clear()
}
// END: Local

const storageClear = () => {
  clearItemCookies()
  clearItemLocalStorage()
}

export const StorageHelpers = {
  getItemCookies,
  setItemCookies,
  removeItemCookies,
  clearItemCookies,
  getItemLocalStorage,
  setItemLocalStorage,
  removeItemLocalStorage,
  clearItemLocalStorage,
  storageClear,
}
