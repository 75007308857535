import React, {FC, useCallback, useContext, useEffect, useState} from 'react'
import CommonService from '../../app/modules/common/core/_requests'
import {StorageHelpers} from '../helpers'

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'I18N_CONFIG_KEY'

type LanguageProviderModel = {
  language: any
  setLanguage: any
  handleUpdateLanguage: any
}

const initLanguageContextPropsState = {
  language: 'en',
  setLanguage: () => {},
  handleUpdateLanguage: () => {},
}

const I18nContext = React.createContext<LanguageProviderModel>(initLanguageContextPropsState)
const MetronicI18nProvider: FC = ({children}) => {
  const [language, setLanguage] = useState(initLanguageContextPropsState.language)

  const handleUpdateLanguage = useCallback(async (userId, language) => {
    setLanguage(language)
    await CommonService.updateLanguage(userId, {language: language})
  }, [])

  useEffect(() => {
    StorageHelpers.setItemLocalStorage(I18N_CONFIG_KEY, language)
  }, [language])

  return (
    <I18nContext.Provider value={{language, setLanguage, handleUpdateLanguage}}>
      {children}
    </I18nContext.Provider>
  )
}

const useLanguage = () => {
  return useContext(I18nContext)
}

export {MetronicI18nProvider, useLanguage}
