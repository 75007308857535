import {FC, lazy} from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import {App} from '../App'
import {Suspense} from 'react'
import {LoadingPage} from '../../_gori/components'
import {I18nProvider} from '../../_gori/i18n/i18nProvider'

const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const AuthWrapper = lazy(() => import('../pages/auth/AuthWrapper'))
  const DepositWrapper = lazy(() => import('../pages/deposit/DepositWrapper'))
  const AccessDenied = lazy(() => import('../pages/errors/ErrorsWrapper'))

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <I18nProvider>
        <Suspense fallback={<LoadingPage />}>
          <Routes>
            <Route path='errors/*' element={<AccessDenied />} />
            <Route element={<App />}>
              <Route path='auth/*' element={<AuthWrapper />} />
              <Route path='deposits/*' element={<DepositWrapper />} />
              <Route path='*' element={<Navigate to={'/deposits'} />} />
            </Route>
          </Routes>
        </Suspense>
      </I18nProvider>
    </BrowserRouter>
  )
}

export {AppRoutes}
