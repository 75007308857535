/* eslint-disable no-undef */
import axios from 'axios'

const API_VERSION = process.env.REACT_APP_API_VERSION
const AUTH_URL = process.env.REACT_APP_AUTH_API_URL
const BASE_URL = `${AUTH_URL}/${API_VERSION}`

const axiosAuthConfig = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosAuthConfig.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axiosAuthConfig.interceptors.response.use(
  (response) => {
    return response.data
  },
  async function (error) {
    return Promise.reject(error)
  }
)

export default axiosAuthConfig
