import ReactDOM from 'react-dom'
import {QueryClient, QueryClientProvider} from 'react-query'
import {AppRoutes} from './app/routing/AppRoutes'
import {MetronicI18nProvider} from './_gori/i18n/Metronici18n'
import {StatesGlobalProvider} from './_gori/providers/StatesGlobalProvider'
import './../node_modules/bootstrap/dist/css/bootstrap.css'
import './../node_modules/bootstrap-daterangepicker/daterangepicker.css'
import './_gori/assets/sass/style.scss'
import './_gori/assets/sass/style.react.scss'
import './_gori/assets/sass/plugins.scss'
import 'react-toastify/dist/ReactToastify.css'

const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <MetronicI18nProvider>
      <StatesGlobalProvider>
        <AppRoutes />
      </StatesGlobalProvider>
    </MetronicI18nProvider>
  </QueryClientProvider>,
  document.getElementById('root')
)
