import {FC} from 'react'
import {useIntl} from 'react-intl'

const Footer: FC = () => {
  const intl = useIntl()

  return (
    <div className='footer py-4 d-flex flex-xxl-column' id='gori_footer'>
      {/* begin::Container */}
      <div className='mx-20 d-flex flex-column flex-md-row flex-stack'>
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1'>
          <span className='text-gray-400 fw-bold me-1'>
            {intl.formatMessage({id: 'COPYRIGHT'}, {year: new Date().getFullYear()})}{' '}
          </span>
          <a
            href='https://gori.ai'
            rel='noreferrer'
            target='_blank'
            className='text-muted text-hover-primary fw-bold me-2 fs-6'
          >
            GORI
          </a>
        </div>
        {/* end::Copyright */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
