import {StorageHelpers, toAbsoluteUrl} from '../helpers'
import {ButtonBack} from '../partials'
import {isNull} from 'lodash'
import {useIntl} from 'react-intl'
const baseDomain = process.env.REACT_APP_SHIPPINGITNOW_DOMAIN

export function Header() {
  const protocol = document.location.protocol
  const slug = StorageHelpers.getItemLocalStorage('slug') || null
  const intl = useIntl()
  const handleRedirectPage = async () => {
    const slugCustom = isNull(slug) ? '' : `${slug}.`
    await document.location.replace(
      `${protocol}//${slugCustom}${baseDomain}${isNull(slug) ? '/' : '/deposits'}`
    )
    await StorageHelpers.clearItemLocalStorage()
  }

  return (
    <div id='gori_header' className='mx-10 mx-xxl-20 mt-10'>
      <div className='d-flex'>
        <ButtonBack event={handleRedirectPage} label={intl.formatMessage({id: 'BACK'})} />
        <div className='d-flex justify-content-center flex-fill' id='gori_header_container'>
          <img
            alt='logo'
            src={toAbsoluteUrl('/media/logos/big-shipbae-logo.png')}
            className='h-30px'
          />
        </div>
      </div>
    </div>
  )
}
